import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { FaMotorcycle } from "react-icons/fa";
import { RiUserLocationFill } from "react-icons/ri";
import { RootState } from "../../../..";
import { createNewBaseService } from "../../../../assets/BaseService";

interface AssignRiderModalProps {
  open: boolean;
  onClose: () => void;
  orderId: string;
  orderDetails: any;
  fetchOrders: (page: number) => Promise<void>;
  currentPage: number;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Rider {
  ID: string;
  Name: string;
  MobileNo: string;
  StationName: string;
  RiderStatus: string;
}

const AssignRiderModal: React.FC<AssignRiderModalProps> = ({
  open,
  onClose,
  orderId,
  orderDetails,
  currentPage,
  fetchOrders,
}) => {
  const [riders, setRiders] = useState<Rider[]>([]);
  const [selectedRider, setSelectedRider] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const NewBaseService = createNewBaseService(pos_config);

  useEffect(() => {
    if (open) {
      fetchRiders();
    } else {
      setSelectedRider("");
      setRiders([]);
      setIsLoading(true);
    }
  }, [open]);

  const fetchRiders = async () => {
    const branchID = localStorage.getItem("branch_id");
    try {
      const response = await NewBaseService.post("/dynamic/get", {
        collection: "ridersinfo",
        filter: {
          RiderStatus: "online",
          OutletID: branchID,
        },
      });
      setRiders(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching riders:", error);
      setIsLoading(false);
    }
  };

  const handleAssignRider = async () => {
    if (!selectedRider || !orderDetails) return;

    const selectedRiderDetails = riders.find(
      (rider) => rider.Name === selectedRider
    );

    try {
      setIsLoading(true);

      const updatePayload = {
        RiderName: selectedRiderDetails?.Name || "",
        RiderPhone: selectedRiderDetails?.MobileNo || "",
        AssignedRiderID: selectedRiderDetails?.ID || "",
        DeliveryStatus: "assigned",
      };

      console.log(
        "Assigning rider - Order payload:",
        JSON.stringify(
          {
            collection: "orders_selected",
            filter: { id: orderId },
            data: updatePayload,
          },
          null,
          2
        )
      );

      await NewBaseService.post("/dynamic/update", {
        collection: "orders_selected",
        filter: { id: orderId },
        data: updatePayload,
      });

      onClose();
      await fetchOrders(currentPage);
    } catch (error) {
      console.error("Error assigning rider:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Modal open={open} onClose={onClose}>
        <div
          className={`${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-2xl shadow-2xl p-8 
                    absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-2xl
                    border ${darkMode ? "border-gray-700" : "border-gray-200"}`}
        >
          <div className="flex justify-center items-center h-32">
            <div className="animate-spin rounded-full h-10 w-10 border-4 border-blue-500 border-t-transparent"></div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div
        className={`${
          darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
        } 
                rounded-2xl shadow-2xl p-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                w-[95%] max-w-2xl border ${
                  darkMode ? "border-gray-700" : "border-gray-200"
                }`}
      >
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-blue-100 rounded-lg">
              <FaMotorcycle className="text-2xl text-blue-600" />
            </div>
            <div className="flex items-center space-x-3">
              <h2 className="text-2xl font-bold">Assign Rider</h2>
              <p className="text-xl opacity-75">#{orderDetails?.code}</p>
            </div>
          </div>
          <button
            onClick={onClose}
            className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          >
            <IoClose size={24} />
          </button>
        </div>

        <div className="space-y-6 mb-6">
          <div
            className={`p-6 rounded-xl ${
              darkMode ? "bg-gray-700" : "bg-gray-50"
            }`}
          >
            <h3 className="font-semibold mb-4 flex items-center">
              <RiUserLocationFill className="mr-2 text-blue-500 text-xl" />
              Available Riders
            </h3>
            <select
              value={selectedRider}
              onChange={(e) => setSelectedRider(e.target.value)}
              className={`w-full p-3 rounded-xl ${
                darkMode ? "bg-gray-600" : "bg-white"
              } 
                                border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent
                                appearance-none cursor-pointer transition-all duration-300`}
            >
              <option value="">Select a rider</option>
              {riders.map((rider) => (
                <option key={rider.ID} value={rider.Name}>
                  {rider.Name} • {rider.MobileNo}
                </option>
              ))}
            </select>
          </div>

          {selectedRider && (
            <div
              className={`p-4 rounded-xl ${
                darkMode ? "bg-gray-700/50" : "bg-blue-50"
              } 
                            border ${
                              darkMode ? "border-gray-600" : "border-blue-100"
                            }`}
            >
              <p className="text-sm">
                Selected rider will be notified immediately upon assignment
              </p>
            </div>
          )}
        </div>

        <button
          onClick={handleAssignRider}
          disabled={!selectedRider}
          className={`w-full py-4 rounded-xl font-semibold flex items-center justify-center space-x-3
                        ${
                          selectedRider
                            ? "bg-blue-500 hover:bg-blue-600 text-white"
                            : "bg-gray-200 dark:bg-gray-700 cursor-not-allowed text-gray-500"
                        }
                        transition-all duration-300 text-lg`}
        >
          <FaMotorcycle className="text-xl" />
          <span>Assign Rider</span>
        </button>
      </div>
    </Modal>
  );
};

export default AssignRiderModal;
