import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SET_DARK_MODE, SET_DRAWER } from "../../Features/leftBarHeader";
import { RootState } from "../..";
import { SET_SELECTEDHEADER } from "../../Features/rightBarHeader";
import { RxHamburgerMenu } from "react-icons/rx";
import HeaderDropdown from "./HeaderDropdown";

function Header(props: any) {
  const dispatch = useDispatch();
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const [username,setUserName] = useState<string | null>(null)
  const [loginTime,setLoginTime] = useState<string | null>(null)

  useEffect(()=>{
    const storedUserName = localStorage.getItem("name");
    const storedLoginTime  = localStorage.getItem("Login Time")

    setUserName(storedUserName);
    setLoginTime(storedLoginTime);
  },[])

  console.log("Image",pos_config.logo_img[0].url)


  return (
    <div
      //@ts-ignore
      className={`bg-[${
        pos_config && pos_config?.header_color
      }] h-[60px] flex justify-between items-center border-[#2b2c37] border-b-[1px]
    px-[8px]
    lg:px-[30px]
    xl:px-[36px]
    `}
    >
      <div
        className={`flex lg:hidden xl:hidden text-white pr-[5px] cursor-pointer`}
        onClick={() => dispatch(SET_DRAWER(true))}
      >
        <RxHamburgerMenu />
      </div>
      <div className="flex items-center justify-center ">
        <img
          className="max-h-[50px] max-w-[150px] h-auto w-auto"
          src={pos_config && pos_config.logo_img && pos_config.logo_img[0].url}
          alt=""
        />
      </div>
      <div
        className={`flex-1 flex items-center justify-end gap-[10px]`}
      >
       {username && (
          <div className="flex flex-col sm:flex-row items-end sm:items-center gap-1 sm:gap-4 text-white">
            <div className="text-sm md:text-lg">Welcome, {username}</div>
            <div className="text-[10px] text-right md:text-sm sm:hidden">Logged on Since:  {loginTime}</div>
            <div className="hidden sm:block text-[10px] md:text-sm">Logged on Since: {loginTime}</div>
          </div>
        )}
        <div className="">
          <HeaderDropdown />
        </div>
      </div>
    </div>
  );
}

export default Header;
